<template>
  <div class="devices">
    <div class="new-campaign new-device">
      <div class="container">
        <div class="header-wrap">
          <h2>{{ $t("devices.title") }}</h2>
        </div>

        <div class="creation-wrap">
          <form @submit="create_device" class="form" method="post">
            <ul class="cf">
              <li class="active"><span class="yellow">1</span>{{ $t("devices.connectDisplay") }}</li>
              <li><span>2</span></li>
              <li><span>3</span></li>
            </ul>

            <div v-if="user.payment_methods">
              <div v-if="user.payment_methods.length < 0">
                <div class="alert alert-danger cf">
                  <p style="margin: 0">
                    {{ $t("global.addPaymentStart") }}
                    <router-link to="/balance/setup_intents/new">{{ $t("global.addPaymentMethod") }}</router-link>
                    {{ $t("global.addPaymentEnd") }}.
                  </p>
                </div>
              </div>
              <div>
                <div class="creation-block">
                  <h3>{{ $t("devices.authTitle") }}</h3>
                  <p>{{ $t("devices.authDescription") }}</p>
                  <div class="form-wrap">
                    <input v-model="device.auth_code" class="form-input" type="text" name="auth_code" id="auth_code" />
                  </div>
                </div>

                <div class="creation-block">
                  <h3>{{ $t("devices.createName") }}</h3>
                  <p>{{ $t("devices.createNameInfo") }}</p>
                  <div class="form-wrap">
                    <input v-model="device.name" class="form-input" type="text" name="auth_code" id="auth_code" />
                  </div>
                </div>

                <p>
                  <input type="submit" :value="$t('devices.step2')" class="button button-ci button-round button-100" />
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  name: "device",
  data() {
    return {
      device: {
        auth_code: "",
        name: "",
      },
      user: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        });
    },
    create_device: function (e) {
      e.preventDefault();

      axios
        .post(process.env.VUE_APP_BASE_API + "/devices/authenticate", this.device, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.$router.push("/devices/" + response.data.device.uuid);
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style type="scss">
.new-campaign .creation-wrap .map-wrap ul li {
  padding-right: 0;
  width: 100%;
}

.new-campaign .creation-wrap .map-wrap ul li::after {
  display: none;
}
</style>
